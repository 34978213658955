const menu = [
    {
        name: 'About',
        icon: 'about',
        path: '/'
    },
    {
        name: 'Resume',
        icon: 'resume',
        path: '/resume'
    },
    {
        name: 'Works',
        icon: 'works',
        path: '/works'
    },
    {
        name: 'Contact',
        icon: 'contact',
        path: '/contact'
    },
]

export default menu;